import * as actions from "./actionTypes";


let initialState = {
  services: [],
  loading: false,
  currentPage: 0,
  hasNextPage: true,
  featuredServices: [],
  ad: {}
}


const servicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.SERVICES_FETCH_START:
      return {
        ...state,
        loading: true
      };

    case actions.SERVICES_FETCH_SUCCESS:
      let currentPage = state.currentPage + 1

      let services
      if (payload.firstPage) {
        services = payload.services
        currentPage = 1

      } else if (payload.services.length > 0) {
        services = [...state.services, ...payload.services]
      } else {
        services = state.services
      }
      const featuredServices = services.slice(0, 2)
      const hasNextPage = services.length !== payload.total

      services = services.filter(service => !featuredServices.map(s => s.id).includes(service.id))

      return {
        ...state,
        loading: false,
        services: services,
        featuredServices: featuredServices,
        hasNextPage: hasNextPage,
        currentPage: currentPage,
        ad: payload.ad,
      };

    case actions.SERVICES_FETCH_FAILED:
      return {
        ...state,
        loading: false,
        hasNextPage: false,
      };

    default:
      return state;
  }
};

export default servicesReducer;
