import React, { Suspense } from "react";
// import Topics from "components/shared/topics";
import ArticlesList from "components/shared/articles_list";
import TermRelatedArticles from "components/shared/term_related_articles";
import { Route } from "react-router-dom";
import * as constants from "variables";
const SideColumn = (props) => {

  const Banner = React.lazy(() => import(/* webpackChunkName: "[request]" */ `components/shared/banner_rectangle`));

  return (
    <>
      {/* <Route path="/search" component={Topics} />
      <Route path="/tags/:slug" component={Topics} /> */}
      <Suspense fallback={<div></div>}>
        <Banner type={constants.BANNER_RECTANGLE} />
      </Suspense>

      <Route path="/terms/:slug" component={ArticlesList} />
      <Route path="/term/:slug" component={TermRelatedArticles} />
    </>
  );
};
export default SideColumn;
