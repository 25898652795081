import * as actions from "./actionTypes";


let initialState = {
  mainArticle: {},
  verticalArticles: [],
  horizontalArticles: [],
  loading: false,
  instagramCaption: {},
  featuredQuestion: {},
}


const latestArticlesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.LATEST_ARTICLES_FETCH_START:
      return {
        ...state,
        loading: true
      };

    case actions.LATEST_ARTICLES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        mainArticle: payload.articles.mainArticle[0] || {},
        verticalArticles: payload.articles.verticlalArticle,
        horizontalArticles: payload.articles.horizontalArticle,
        instagramCaption: payload.instagramCaption,
        featuredQuestion: payload.question?.horizontalQuestion,
      };

    case actions.LATEST_ARTICLES_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default latestArticlesReducer;
