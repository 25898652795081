export const TEMPLATES_FETCH_START = "TEMPLATES_FETCH_START";
export const TEMPLATES_FETCH_SUCCESS = "TEMPLATES_FETCH_SUCCESS";
export const TEMPLATES_FETCH_FAILED = "TEMPLATES_FETCH_FAILED";

export const BUNDLES_FETCH_START = "BUNDLES_FETCH_START";
export const BUNDLES_FETCH_SUCCESS = "BUNDLES_FETCH_SUCCESS";
export const BUNDLES_FETCH_FAILED = "BUNDLES_FETCH_FAILED";

export const FILTER_TEMPLATES_FETCH_SUCCESS = "FILTER_TEMPLATES_FETCH_SUCCESS";
export const TEMPLATES_SEARCH_FETCH_SUCCESS = "TEMPLATES_SEARCH_FETCH_SUCCESS";

export const TAGS_FETCH_START = "TAGS_FETCH_START";
export const TAGS_FETCH_SUCCESS = "TAGS_FETCH_SUCCESS";
export const TAGS_FETCH_FAILED = "TAGS_FETCH_FAILED";

export const TEMPLATES_AND_BUNDLES_SEARCH = "TEMPLATES_AND_BUNDLES_SEARCH";
