import React from "react";
import "./article_small.scss";
import thumbSm from "../../../assets/img/thumb_rectangle.png";
import Util from "../../../util"

import { Link } from "react-router-dom";
// import Settings from "../../../services/settings.service";

const ArticleBlockSmall = (props) => {
  let { data } = props;

  let orderDateYear = "#";
  let orderDateMonth = "#";
  let orderDateDay = "#";
  let content = null;
  if (data) {
    orderDateYear = new Date(data.createdAt).getFullYear();
    orderDateMonth = new Date(data.createdAt).getMonth() + 1;
    orderDateDay = new Date(data.createdAt).getDate();
    console.log("Article Small" , {data})

    let relatedPhotoImage = thumbSm
    if ( data?.relatedPhoto?.square) {

      relatedPhotoImage = Util.getResizedImage( data?.relatedPhoto?.square , 150, 90)
      
    }else{
      if( data?.square){
      relatedPhotoImage = Util.getResizedImage( data?.square , 150, 90)
      }
    }

    console.log({relatedPhotoImage})

    
    let relatedPhoto = <img src={relatedPhotoImage} alt={data.articleTitleInArabic} className="articleBlockImg" loading="lazy" />;


    content = (
      <div className="articleBlockSmall">
        <Link to={"/article/" + data.slug}>
          <span className="articleBlockImgWrapperSmall">{relatedPhoto}</span>
          <span className="articleBlockContentWrapperSmall">
            <span className="articleTitleSmall">{data.articleTitleInArabic}</span>
            <span className="articleInfo">
              <span className="articleWrite">{data.authorName}</span>
              <span className="articlePublishedDate">{orderDateDay + "/" + orderDateMonth + "/" + orderDateYear}</span>
            </span>
          </span>
        </Link>
      </div>
    );
  }

  return content;
};
export default ArticleBlockSmall;
