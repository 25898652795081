import * as actions from "./actionsType";

let initialState = {
  loggedIn: false,
  error: null,
  loading: false,
  userInfo: null,
};
let userReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actions.LOGIN_SUCCESS:
      let updateUserData = { ...action.userInfo };
      updateUserData["name"] = { ...action.userInfo.name };
      updateUserData["profilePicture"] = { ...action.userInfo.profilePicture };

      return {
        ...state,
        userInfo: updateUserData,
        error: null,
        loading: false,
        loggedIn: true,
        activate: action.userInfo.activated,
      };

    case actions.ACTIVATE_ACCOUNT:
      alert(action.userInfo);
      let updateUserInfo = { ...action.userInfo };
      updateUserInfo["name"] = { ...action.userInfo.name };
      updateUserInfo["profilePicture"] = { ...action.userInfo.profilePicture };

      return {
        ...state,
        userInfo: updateUserInfo,
        error: null,
        loading: false,
        loggedIn: true,
        activate: action.userInfo.activated,
      };

    case actions.LOGIN_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        loggedIn: false,
        userInfo: null,
        activate: false,
      };

    case actions.REGISTER_START:
      return {
        ...state,
        error: null,
        loading: true,
      };

    case actions.REGISTER_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        loggedIn: false,
        userInfo: null,
        activate: false,
      };

    case actions.LOGOUT:
      return {
        ...state,
        loggedIn: false,
        error: null,
        loading: false,
        userInfo: null,
        activate: false,
      };

    case actions.RESET_ERROR:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};
export default userReducer;
