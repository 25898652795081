import React from "react";
import "./style.scss";

const Textarea = ({
  state,
  change,
  rows = 4,
  cols = 50,
  value = "",
  name,
  id,
  classes,
  error,
  placeholder,
  blur,
}) => {
  let [displayError, updateDisplayError] = React.useState(false);
  let [newClasses, updateClasses] = React.useState([...classes]);

  const isEnglish = React.useRef(false);

  const updateTextarea = (e) => {
    console.log("e.target.value===", e.target.value);
    change(name, e.target.value, state);
    let val = e.target.value;
    var arabic = /[\u0600-\u06FF]/;
    var result = arabic.test(val);
    if (val === "") {
      isEnglish.current = false;
    } else {
      if (!result) {
        isEnglish.current = true;
        console.log("language");
      } else {
        isEnglish.current = false;
      }
    }
  };

  let errorsMessage = null;
  const checkValidation = () => {
    // console.log('checkValidation[' + name + ']==', error.length)
    if (error.length) {
      updateClasses([...classes, "error"]);
      updateDisplayError(true);
    } else {
      updateClasses([...classes]);
      updateDisplayError(false);
    }
    if (blur) {
      blur();
    }
  };

  if (error.length && displayError) {
    errorsMessage = error.map((err) => (
      <div className="error-message" key={err}>
        {err}
      </div>
    ));
  }

  if (state.touched) {
    newClasses.push("touched");
  }

  let englishInput = (
    <>
      <textarea
        onBlur={checkValidation}
        name={name}
        id={id}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        onChange={(e) => updateTextarea(e)}
        className="searchInputTextEnglish"
        value={value}
      />
    </>
  );

  let arabic = (
    <>
      <textarea
        onBlur={checkValidation}
        name={name}
        id={id}
        rows={rows}
        cols={cols}
        placeholder={placeholder}
        onChange={(e) => updateTextarea(e)}
        className="searchInputText"
        value={value}
      />
    </>
  );

  return (
    <div className="input-field-block">
      {isEnglish.current ? englishInput : arabic}
      {displayError ? errorsMessage : null}
    </div>
  );
};

export default Textarea;
