class Util {
  static arrayToKeyedObject = (arr, key) => {
    let obj = {};
    for (let item of arr) {
      obj[item[key]] = item;
    }
    return obj;
  };

  static isMobile = (checkSize = 900) => {
    var w = Math.max(
      document.documentElement.clientWidth,
      window.innerWidth || 0
    );
    return w <= checkSize;
  };

  static mergeObjects = (obj, obj2) => {
    
    let newObj = {};
    let objKeys = Object.keys(obj);
    let objKeys2 = Object.keys(obj2);
    for (let key of objKeys) {
      //   console.log('state[key]=', state[key]);
      if (typeof obj[key] === "object") {
        if (Array.isArray(obj[key])) {
          newObj[key] = obj[key].slice();
        } else {
          newObj[key] = { ...obj[key] };
        }
      } else {
        newObj[key] = obj[key];
      }
    }
    for (let key of objKeys2) {
      //   console.log('state[key]=', state[key]);
      if (typeof obj2[key] === "object") {
        if (Array.isArray(obj2[key])) {
          newObj[key] = obj2[key].slice();
        } else {
          newObj[key] = { ...obj2[key] };
        }
      } else {
        newObj[key] = obj2[key];
      }
    }

    return newObj;
  };

  static startWithPattern = (url, pattern) => {
    let urlLength = url.length;
    let patternLength = pattern.length;
    if (urlLength < patternLength) return false;
    return url.substring(0, patternLength) === pattern;
  };

  static inputClasses = (elem, defaultClasses, state) => {
    // let elemValid = true;
    let newClasses = [...defaultClasses];
    if (state["touched"]) {
      if (state["value"].length > 0) {
        newClasses.push("touched");
      }
    }
    return newClasses;
  };

  static changeHandler = (elem, newValue, state) => {
   
    let newState = {};
    let formKeys = Object.keys(state);

    for (let key of formKeys) {
      if (typeof state[key] === "object") {
        if (Array.isArray(state[key])) {
          newState[key] = state[key].slice();
        } else {
          newState[key] = { ...state[key] };
        }
      } else {
        newState[key] = state[key];
      }
    }

    newState["touched"] = true;
    newState["errors"] = [];

    let rules = [...newState["rules"]];
    let rulesType = newState["rulesType"];

    let valid;
    if (rulesType === "any") {
      valid = false;
      for (let pattern of rules) {
        valid = valid || pattern.test(newValue);
      }
    } else {
      // all
      valid = true;
      let rulesLength = rules.length;
      // console.log('rulesLength=', rulesLength)
      for (let i = 0; i < rulesLength; i++) {
        let validRule = rules[i].test(newValue);
        if (!validRule) {
          newState["errors"].push(newState["errorMessage"][i]);
        }
        valid = valid && validRule;
      }
    }
    newState["validation"] = valid;
    newState["value"] = newValue;
    return newState;
  };

  static setCookie = (name, value, exdays) => {
    var d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    var expires = "expires=" + d.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
  };

  static getCookie = (name) => {
    if (document.cookie.indexOf(name + "=") === -1) {
      return "";
    } else {
      var pieces = document.cookie.split(";");
      for (var i = 0; i < pieces.length; i++) {
        var piece = pieces[i].trim();
        if (piece.indexOf(name + "=") !== -1) {
          let result = piece.split("=");
          return result[1];
        }
      }
      return "";
    }
  };

  static getQueries = (query) => {
    let queryString = "";
    let queriesObj = {};

    if (window.location.search) {
      console.log("window.location.search.substr(1)");
      queryString = window.location.search.substr(1).split("&");
      console.log("queryString");
      let queryStringLength = queryString.length;
      for (let i = 0; i < queryStringLength; i++) {
        console.log("queryString[i]" + queryString[i]);
        let element = queryString[i].split("=");
        console.log("element" + queryString[i]);
        queriesObj[element[0]] = element[1];
      }

      if (query === "page") {
        if (!queriesObj[query]) {
          return 1;
        }
      }
      return queriesObj[query];
    }
  }

  static removeTrailingSlash(path){
    if(path && path.charAt(path.length - 1) === '/'){
     return path.slice(0 , path.length - 1)
    }
    return path
  }


  static getResizedImage(imageUrl , width, height = 0 , fit= 'cover', cacheLevel='disk'){
    if(process.env.REACT_APP_ENABLE_IMAGE_RESIZING){
        const newImageURL =  imageUrl.replace(process.env.REACT_APP_CDN_URL , `${process.env.REACT_APP_IMAGE_RESIZING_BASE_PATH}${width}/${height}/`)
        console.log("Resizing" , newImageURL)
        return newImageURL+`?fit=${fit}&cacheLevel=${cacheLevel}&v=5`
    }
    return imageUrl
    
  }
}

export default Util;
