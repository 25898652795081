import HTTP from "./http.service";

class TopicsService {
  static getTopics() {
    let url = `topics`;
    const config = {
      method: "get",
      url: url,
    };
    return HTTP(config);
  }

  static getTopicsByTermId(id) {
    let url = `topic/${id}`;
    const config = {
      method: "get",
      url: url,
    };
    return HTTP(config);
  }

  static getTermsByTopicSlug(slug, loggedin, page = 1) {
    let url = `terms/getByTopic/${slug}?page=${page}`;
    const config = {
      method: "get",
      url: url,
      headers: {
        needAutherization: true,
      },
    };
    if (loggedin) {
      config.headers["needAutherization"] = true;
    }
    return HTTP(config);
  }

  static getArticlesByTopicSlug(slug, page = 1) {
    let url = `article/getByTopic/${slug}?page=${page}`;
    const config = {
      method: "get",
      url: url,
    };
    return HTTP(config);
  }

  static getTemplatesByTopicSlug(slug, page = 1) {
    let url = `template/getByTopic/${slug}?page=${page}`;
    const config = {
      method: "get",
      url: url,
    };
    return HTTP(config);
  }
}

export default TopicsService;
