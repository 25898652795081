import * as actions from "./actionTypes";


let initialState = {
  terms: [],
  loading: false,
}


const homeLatestTermsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.HOME_LATEST_TERMS_FETCH_START:
      return {
        ...state,
        loading: true
      };

    case actions.HOME_LATEST_TERMS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        terms: payload,
      };

    case actions.HOME_LATEST_TERMS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default homeLatestTermsReducer;
