export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const REGISTER_START = "REGISTER_START";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILED = "REGISTER_FAILED";

export const RESET_ERROR = "RESET_ERROR";

export const LOGOUT = "LOGOUT";
export const SET_USER_INFO = "SET_USER_INFO";

export const SOCIAL_SIGNIN_START = "SOCIAL_SIGNIN_START";
export const SOCIAL_SIGNIN_SUCCESS = "SOCIAL_SIGNIN_SUCCESS";
export const SOCIAL_SIGNIN_FAILED = "SOCIAL_SIGNIN_FAILED";

export const RESET_ERRORS = "RESET_ERRORS";

export const SOCIAL_SIGNUP_FAILED = "SOCIAL_SIGNUP_FAILED";

export const ACTIVATE_ACCOUNT = "ACTIVATE_ACCOUNT";
