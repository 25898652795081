export const FOCUS = "FOCUS";
export const SEARCH_START = "SEARCH_START";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILED = "SEARCH_FAILED";

export const SEARCH_AUTOCOMPLETE_START = "SEARCH_AUTOCOMPLETE_START";
export const SEARCH_AUTOCOMPLETE_SUCCESS = "SEARCH_AUTOCOMPLETE_SUCCESS";
export const SEARCH_AUTOCOMPLETE_FAILED = "SEARCH_AUTOCOMPLETE_FAILED";
export const SEARCH_AUTOCOMPLETE_RESET = "SEARCH_AUTOCOMPLETE_RESET";

export const SEARCH_WRITING = "SEARCH_WRITINNG";
export const SEARCH_KEYWORD = "SEARCH_KEYWORD";

export const SEARCH_RESET = "SEARCH_RESET";
export const SEARCH_CLOSE = "SEARCH_CLOSE";
export const SEARCH_CHANGE_TYPE = "SEARCH_CHANGE_TYPE";

export const SEARCH_WAITE = "SEARCH_WAITE";

export const TAGS_LIST_START = "TAGS_LIST_START";
export const TAGS_LIST_SUCCESS = "TAGS_LIST_SUCCESS";
export const TAGS_LIST_FAILED = "TAGS_LIST_FAILED";

export const TERM_START = "TERM_START";
export const TERM_SUCCESS = "TERM_SUCCESS";
export const TERM_FAILED = "TERM_FAILED";

export const TERMS_START = "TERMS_START";
export const TERMS_SUCCESS = "TERMS_SUCCESS";
export const TERMS_FAILED = "TERMS_FAILED";

export const ARTICLES_START = "ARTICLES_START";
export const ARTICLES_SUCCESS = "ARTICLES_SUCCESS";
export const ARTICLES_FAILED = "ARTICLES_FAILED";

export const SIMILAR_TERMS_START = "SIMILAR_TERMS_START";
export const SIMILAR_TERMS_SUCCESS = "SIMILAR_TERMS_SUCCESS";
export const SIMILAR_TERMS_FAILED = "SIMILAR_TERMS_FAILED";

export const RELATED_TEMPLATES_SUCCESS = "RELATED_TEMPLATES_SUCCESS";
export const RELATED_SERVICE_SUCCESS = "RELATED_SERVICE_SUCCESS";
export const RELATED_TERMS_SUCCESS = "RELATED_TERMS_SUCCESS";
export const RELATED_ADS_SUCCESS = "RELATED_ADS_SUCCESS";

export const ARTICLES_LIST_START = "ARTICLES_LIST_START";
export const ARTICLES_LIST_SUCCESS = "ARTICLES_LIST_SUCCESS";
export const ARTICLES_LIST_FAILED = "ARTICLES_LIST_FAILED";

export const ADD_LIKE_START = "ADD_LIKE_START";
export const ADD_LIKE_SUCCESS = "ADD_LIKE_SUCCESS";
export const ADD_LIKE_FAILED = "ADD_LIKE_FAILED";

export const REMOVE_LIKE_START = "REMOVE_LIKE_START";
export const REMOVE_LIKE_SUCCESS = "REMOVE_LIKE_SUCCESS";
export const REMOVE_LIKE_FAILED = "REMOVE_LIKE_FAILED";

export const INITIATE_SUGGESTION_OPTION = "INITIATE_SUGGESTION_OPTION";

export const HANDLE_CHANGE = "HANDLE_CHANGE";
export const FORM_VALIDATION = "FORM_VALIDATION";

export const SHARE = "SHARE";
export const BOOKMARK = "BOOKMARK";

export const BOOKMARKED_ADD_SUCCESS = "BOOKMARKED_ADD_SUCCESS";
export const BOOKMARKED_ADD_FAILED = "BOOKMARKED_ADD_FAILED";

export const BOOKMARKED_REMOVE_SUCCESS = "BOOKMARKED_REMOVE_SUCCESS";
export const BOOKMARKED_REMOVE_FAILED = "BOOKMARKED_REMOVE_FAILED";

export const FORGET_PASSWORD_START = "FORGET_PASSWORD_START";
export const FORGET_PASSWORD_SUCCESS = "FORGET_PASSWORD_SUCCESS";
export const FORGET_PASSWORD_FAILED = "FORGET_PASSWORD_FAILED";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILED = "RESET_PASSWORD_FAILED";

export const ARTICLES = "ARTICLES";
export const TERMS = "TERMS";
export const TEMPLATES = "TEMPLATES";
export const SERVICES = "SERVICES";

export const MY_ARTICLES = "MY_ARTICLES";
export const MY_TERMS = "MY_TERMS";

export const BANNER_RECTANGLE = "BANNER_RECTANGLE";

export const ARTICLE_FETCH_START = "ARTICLE_FETCH_START";
export const ARTICLE_FETCH_SUCCESS = "ARTICLE_FETCH_SUCCESS";
export const ARTICLE_FETCH_FAILED = "ARTICLE_FETCH_FAILED";

export const BOOKMARKED_START = "BOOKMARKED_START";
export const BOOKMARKED_SUCCESS = "BOOKMARKED_SUCCESS";
export const BOOKMARKED_FAILED = "BOOKMARKED_FAILED";

export const BOOKMARKED_ARTICLE_SUCCESS = "BOOKMARKED_ARTICLE_SUCCESS";
export const BOOKMARKED_TEMPLATE_SUCCESS = "BOOKMARKED_TEMPLATE_SUCCESS";

export const REQUEST_ADDING_TERM_START = "REQUEST_ADDING_TERM_START";
export const REQUEST_ADDING_TERM_SUCCESS = "REQUEST_ADDING_TERM_SUCCESS";
export const REQUEST_ADDING_TERM_FAILED = "REQUEST_ADDING_TERM_FAILED";

export const CHANGE_TAB = "CHANGE_TAB";

export const SET_TAG_SLUG = "SET_TAG_SLUG";

export const START = "START";
export const SUCCESS = "SUCCESS";
export const FAILED = "FAILED";
export const RESET = "RESET";

export const SERVICE_START = "SERVICE_START";
export const SERVICE_SUCCESS = "SERVICE_SUCCESS";
export const SERVICE_FAILED = "SERVICE_FAILED";

export const TEMPLATE_START = "TEMPLATE_START";
export const TEMPLATE_SUCCESS = "TEMPLATE_SUCCESS";
export const TEMPLATE_FAILED = "TEMPLATE_FAILED";

export const TEMPLATE_FETCH_START = "TEMPLATE_FETCH_START";
export const TEMPLATE_FETCH_SUCCESS = "TEMPLATE_FETCH_SUCCESS";
export const TEMPLATE_FETCH_FAILED = "TEMPLATE_FETCH_FAILED";

export const TEMPLATE_LIST_START = "TEMPLATE_LIST_START";
export const TEMPLATE_LIST_SUCCESS = "TEMPLATE_LIST_SUCCESS";
export const TEMPLATE_LIST_FAILED = "TEMPLATE_LIST_FAILED";
export const FORM_EMAIL = "FORM_EMAIL";

export const DESCRIPTION = "DESCRIPTION";
export const USER_INFORMATION = "USER_INFORMATION";
export const REST_FORM = "REST_FORM";
