import * as constants from "variables";
import Settings from "services/settings.service";
export const initRequestAddingTermState = {
  formValid: false,
  form: {
    email: {
      value: "",
      touched: false,
      validation: false,
      rules: [Settings.format.email],
      errorMessage: ["البريد الإلكتروني غير صحيح"],
      errors: [],
      rulesType: "all", //values => any | all
    },
    message: {
      value: "",
      touched: false,
      validation: false,
      rules: [Settings.format.message],
      errorMessage: ["الرسالة يجب ان لا تقل عن ٦ حروف"],
      errors: [],
      rulesType: "all", //values => any | all
    },
  },
  loading: false,
  success: false,
};
const checkFormValidation = () => {
  let formElements = Object.keys(initRequestAddingTermState.form);
  let formElementsLength = formElements.length;

  let formValid = true;
  for (let i = 0; i < formElementsLength; i++) {
    formValid =
      formValid &&
      initRequestAddingTermState["form"][formElements[i]].validation;
  }
  return formValid;
};
export const requestAddingTermStateReducer = (state, action) => {
  switch (action.type) {
    case constants.HANDLE_CHANGE:
      let form = state.form;
      form[action.field] = action.payload;
      return {
        ...state,
        form: form,
      };

    case constants.FORM_VALIDATION:
      let formValidation = checkFormValidation();
      return {
        ...state,
        formValid: formValidation,
      };
    case constants.REQUEST_ADDING_TERM_START:
      return {
        ...state,
        loading: true,
      };
    case constants.REQUEST_ADDING_TERM_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
      };
    case constants.REQUEST_ADDING_TERM_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
      };

    default:
      return state;
  }
};
