import React from 'react';
import './articles_list.scss';
import TopicsService from 'services/topics.service';
import ArticleBlockSmall from '../article_small'
const ArticlesList = (props) => {

    console.log('ArticlesList props=', props)
    let { match } = props;
    let [articles, updateArticles] = React.useState(null);


    React.useEffect(() => {
        TopicsService.getArticlesByTopicSlug(match.params['slug'])
            .then(response => {
                updateArticles(response.data)
            })
            .catch(error => {
                console.log('error=', error)
            })
    }, [match])


    let list = null;
    if (articles && articles.length) {
        list = articles.map(article => <ArticleBlockSmall key={article.id} data={article} />)
    }

    return (
        <div className="articlesListBlock">
            {list}
        </div>

    )
}
export default ArticlesList;