/* App settings */
import Util from "../util";
import webSettings from "../settings.json";

const Settings = Util.mergeObjects(process.env, webSettings);

Settings.get = function (setting) {
  
  return Settings[setting];
};

Settings.format = {
  email: /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: /([^\s]*)/,

  // password: /^(?=).{8,}$/,
  // password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{6,}$/
  password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d!$%@#£€*?&_=+-]{6,}$/,
  passwordMinLength: /^[a-zA-Z\d!$%@#£€*?&_=+-]{6,}$/,
  passwordOneUpper: /(?=.*[A-Z])/,
  passwordOneLower: /(?=.*[a-z])/,
  passwordOneDigit: /(?=.*\d)/,
  // notEmptyString: /([^\s]*)/,
  notEmptyString: /^(?!\s*$).+/,
  validDate:/^((0[1-9])|(1[0-2]))\/(\d{2})$/,
  number:/[0-9]{3,3}$/,
  visaRegEx : /^(?:4[0-9]{12}(?:[0-9]{3})?)$/,
  mastercardRegEx : /^(?:5[1-5][0-9]{14})$/,
  amexpRegEx : /^(?:3[47][0-9]{13})$/,
  discovRegEx : /^(?:6(?:011|5[0-9][0-9])[0-9]{12})$/,
  cardNumber:/^(\d{4}[- ]){3}\d{4}|\d{16}$/,
  securityCode:/^([0-9]{3,4})$/

};

export default Settings;
