import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import thunk from "redux-thunk";

// import layoutReducer from '../Layout/layoutReducer';
import userReducer from "../User/userReducer";
import topicsReducer from "../Topics/topicsReducer";
import searchReducer from "../Search/searchReducer";
import templatesReducer from "../Templates/reducer";
import servicesReducer from "../Services/reducer";
import latestArticlesReducer from "../LatestArticles/reducer";
import homeTemplatesReducer from "../HomeTemplates/reducer";
import homeServicesReducer from "../HomeServices/reducer";
import homeQAReducer from "../HomeQA/reducer";
import homeTermReducer from "../HomeTerm/reducer";
import homeLatestTermsReducer from "../HomeLatestTerms/reducer";
import homeBooksReducer from "../HomeBooks/reducer";
// import loadingReducer from '../Loading/loadingReducer';
// import menuReducer from '../Menu/menuReducer';
// import faqReducer from '../Faq/faqReducer';
// import shopReducer from '../Shop/shopReducer';
// import mapReducer from '../Map/mapReducer';
// import dashboardReducer from '../Dashboard/dashboardReducer';

const rootReducer = combineReducers({
  // layout: layoutReducer,
  user: userReducer,
  topics: topicsReducer,
  search: searchReducer,
  templates: templatesReducer,
  services: servicesReducer,
  latestArticles: latestArticlesReducer,
  homeTemplates: homeTemplatesReducer,
  homeServices: homeServicesReducer,
  homeQA: homeQAReducer,
  homeTerm: homeTermReducer,
  homeLatestTerms: homeLatestTermsReducer,
  homeBooks: homeBooksReducer,
  // loading: loadingReducer,
  // menu: menuReducer,
  // faq: faqReducer,
  // shop: shopReducer,
  // map: mapReducer,
  // dashboard: dashboardReducer
});
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

export default store;
