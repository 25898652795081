import React from "react";
import { Switch, Route } from "react-router-dom";
import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import SEOContent from "components/shared/seo_header";

const pages = [
  { path: "/", componentPath: "informative/v2/HomePage", enableSeo: true, seoTemplate: "home" },
  { path: "/login", componentPath: "informative/signin_signup", enableSeo: false, seoTemplate: "" },
  { path: "/forget-password", componentPath: "informative/signin_signup/forget_password", enableSeo: true, seoTemplate: "default" },
  { path: "/reset-password", componentPath: "informative/signin_signup/reset_password", enableSeo: true, seoTemplate: "default" },
  { path: "/search/:keyword", componentPath: "informative/search_result", enableSeo: false, seoTemplate: "" },
  { path: "/profile", componentPath: "dashboard/profile", enableSeo: true, seoTemplate: "profile" },
  { path: "/profile/:bookmarksType", componentPath: "dashboard/profile/my_bookmarks", enableSeo: true, seoTemplate: "myBookmarked" },
  { path: "/tags/:slug", componentPath: "informative/tag", enableSeo: false, seoTemplate: "" },
  { path: "/article/:slug", componentPath: "informative/article", enableSeo: false, seoTemplate: "" },
  { path: "/articles/:topicSlug", componentPath: "informative/articles", enableSeo: false, seoTemplate: "" },
  { path: "/term/:slug", componentPath: "informative/term", enableSeo: false, seoTemplate: "" },
  { path: "/terms/:topicSlug", componentPath: "informative/terms_by_topic", enableSeo: false, seoTemplate: "" },
  { path: "/policy", componentPath: "informative/privacy_policy_page", enableSeo: false, seoTemplate: "" },
  { path: "/privacy-policy", componentPath: "informative/privacy_policy_page", enableSeo: false, seoTemplate: "" },
  { path: "/services/:topicSlug", componentPath: "informative/v2/Services/container", enableSeo: false, seoTemplate: "" },
  { path: "/service/:slug", componentPath: "informative/services", enableSeo: false, seoTemplate: "" },
  { path: "/downloaded-template", componentPath: "informative/downloaded_templates_page", enableSeo: false, seoTemplate: "" },
  { path: "/template/:slug", componentPath: "informative/template_page", enableSeo: false, seoTemplate: "" },
  // { path: "/templates/:topicSlug", componentPath: "informative/template_list_page", enableSeo: false, seoTemplate: "" },
  { path: "/book/:slug", componentPath: "informative/book_summary", enableSeo: false, seoTemplate: "" },
  { path: "/templates/:topicSlug", componentPath: "informative/v2/Templates/container", enableSeo: false, seoTemplate: "" },
  { path: "/activation", componentPath: "informative/signin_signup/activation", enableSeo: false, seoTemplate: "" },

  { path: "/donate", componentPath: "informative/donate", enableSeo: false, seoTemplate: "" },

  // { path: "/:topicSlug", componentPath: "informative/topic", enableSeo: false, seoTemplate: "" }

  { path: "/all-topics", componentPath: "informative/topic", enableSeo: false, seoTemplate: "" },
  { path: "/management", componentPath: "informative/topic", enableSeo: false, seoTemplate: "" },
  { path: "/economics-and-finance", componentPath: "informative/topic", enableSeo: false, seoTemplate: "" },
  { path: "/accounting", componentPath: "informative/topic", enableSeo: false, seoTemplate: "" },
  { path: "/marketing", componentPath: "informative/topic", enableSeo: false, seoTemplate: "" },
];

const Routes = (props) => {
  const routeElements = pages.map((routeElem) => {
    console.log("path=", routeElem.componentPath);
    let C = React.lazy(() => import(/* webpackChunkName: "[request]" */ `../pages/${routeElem.componentPath}`));

    return (
      <Route
        key={routeElem.path}
        path={routeElem.path}
        exact={true}
        render={(props) => {
          let seoComponent = null;
          if (routeElem.enableSeo) {
            seoComponent = <SEOContent page={routeElem.seoTemplate} />;
          }
          return (
            <>
              {seoComponent}
              <C {...props} />
            </>
          );
        }}
      />
    );
  });

  const Common404 = React.lazy(() => import(/* webpackChunkName: "[request]" */ `../pages/informative/404`));

  return (
    <Switch>
      <Route exact path="/linkedIn" component={LinkedInPopUp} />
      {routeElements}
      <Route component={Common404} />
    </Switch>
  );
};
export default Routes;
