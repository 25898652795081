import React from "react";
import { withRouter } from "react-router-dom";
import { hideSearch } from "stores/Search/searchActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.props.hideSearch();
      window.scrollTo(0, 0);
      document.body.classList.remove("overflow");
      document.body.classList.remove("displayNav");
      document.getElementById("burger-btn").classList.remove("opened");
    }
  }

  render() {
    return this.props.children;
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ hideSearch }, dispatch);
};
export default connect(null, mapDispatchToProps)(withRouter(ScrollToTop));
