import HTTP from "./http.service";

class SearchService {
  static getGlobalAutocomplete(search) {
    let url = `terms/v2/globalSuggest/${search}`;
    const config = {
      method: "get",
      url: url,
    };
    return HTTP(config);
  }

  static globalSearch(keyword, type, page = 1) {
    let url = `terms/v2/globalSearch/${keyword}/${type}?page=${page}`;
    const config = {
      method: "get",
      url: url,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static requestAddingTerm(data) {
    let url = `enquiries`;
    const config = {
      method: "post",
      url: url,
      data: JSON.stringify(data),
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static requestAddingFile(formData, id) {
    let url = `enquiries/addFile/${id}`;
    const config = {
      method: "post",
      url: url,
      data: formData,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static contactUs(data) {
    let url = `enquiries`;
    const config = {
      method: "post",
      url: url,
      data: JSON.stringify(data),
    };
    return HTTP(config);
  }
}

export default SearchService;
