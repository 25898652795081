import React from "react";

import "./Modal.scss";

const Modal = (props) => {
  let paddingStyle = {};
  if (props.paddingStyle) {
    paddingStyle = { padding: props.paddingStyle + "px" };
  }

  if (props.height) {
    paddingStyle["height"] = props.height + "px";
  }

  if (props.size) {
    paddingStyle["maxWidth"] = props.size + "px";
    paddingStyle["transform"] = "translateY(30px)";
  }

  if (props.textAlign) {
    paddingStyle["textAlign"] = props.textAlign;
  }

  let classes = ["modal-wrapper"];
  if (props.type) {
    classes.push("withShadow");
  }

  return (
    <div className="modal">
      <div className={classes.join(" ")} style={paddingStyle}>
        {props.close && <div className="modal-close" onClick={props.close}></div>}
        <div className="modal-content">{props.children}</div>
      </div>
    </div>
  );
};

export default Modal;
