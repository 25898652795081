import UserService from "services/user.service";
import * as actions from "./actionsType";
import StorageService from "services/storage.service";
import HTTP from "services/http.service";
// import HTTP from '../../services/http.service';

export const removeUserStorage = () => {
  // sessionStorage.removeItem('authToken');
  // sessionStorage.removeItem('first_name');
  // sessionStorage.removeItem('last_name');
  StorageService.removeStorageElement("authToken");
  StorageService.removeStorageElement("first_name");
  StorageService.removeStorageElement("last_name");
  StorageService.removeStorageElement("userData");
};

export const setUserStorage = (auth_token, first_name, last_name, userInfo = null) => {
  StorageService.setStorageElement("authToken", auth_token);
  StorageService.setStorageElement("first_name", first_name);
  StorageService.setStorageElement("last_name", last_name);
  if (userInfo) {
    StorageService.setStorageElement("userData", userInfo);
  }

  // sessionStorage.setItem('authToken', auth_token);
  // sessionStorage.setItem('first_name', first_name);
  // sessionStorage.setItem('last_name', last_name);
};

export const setLoginStorage = (isRememberMeChecked, email, password) => {
  StorageService.setLoginStorage(isRememberMeChecked, email, password);
};
export const removeLoginStorage = (isRememberMeChecked) => {
  StorageService.removeLoginStorage(isRememberMeChecked);
};

export const getLoginStorage = () => {
  StorageService.getLoginStorage();
};

export const startLogin = () => {
  return {
    type: actions.LOGIN_START,
  };
};

export const loginFailed = (error) => {
  let errorMsg = "";
  debugger;
  switch (error.response ? error.response.status : error.response) {
    case 510:
      errorMsg = "البريد الإلكتروني غير موجود";
      break;
    case 500:
      errorMsg = "يوجد مشكلة تقنية، سنعود لاحقاً";
      break;
    case 401:
      errorMsg = "كلمة المرور غير صحيحة";
      break;
    default:
  }
  return {
    type: actions.LOGIN_FAILED,
    errorMsg: errorMsg,
  };
};

export const loginSuccess = (userInfo, isRememberMeChecked) => {
  setActivateStorage(userInfo.activated);
  document.cookie = `user=${JSON.stringify(userInfo)};max-age=${60 * 60 * 24 * 365};path=/;`;
  HTTP.set_session(userInfo.token);

  return {
    type: actions.LOGIN_SUCCESS,
    userInfo: userInfo,
  };
};

export const activate = (userInfo) => {
  document.cookie = `user=${JSON.stringify(userInfo)};max-age=${60 * 60 * 24 * 365};path=/;`;
  HTTP.set_session(userInfo.token);
  return {
    type: actions.ACTIVATE_ACCOUNT,
    userInfo: userInfo,
  };
};
export const setActivateStorage = (activate) => {
  StorageService.setActivateStorage(activate);
};

export const getActivate = () => {
  StorageService.getActivateStorage();
};

export const login = (data) => {
  return (dispatch) => {
    dispatch(startLogin());
    UserService.login(data)
      .then((response) => {
        console.log("response=", response);

        // document.cookie = `user=${JSON.stringify(response.data.result)};max-age=${60}`;
        // window.auth = response.data.auth_token;
        // HTTP.set_session(window.auth)
        // StorageService.setStorageElement('authToken', window.auth)
        dispatch(loginSuccess(response.data.result));
      })
      .catch((error) => {
        console.log(error);

        dispatch(loginFailed(error));
      });
  };
};
///////////////////////////////////////////////////////////
// export const activateAccount = (data) => {
//   ;
//   return (dispatch) => {
//     dispatch(startLogin());

//     UserService.activateAccount(data)
//       .then((response) => {
//         ;
//         console.log("response=", response);
//         dispatch(activate(response.data.result));
//       })
//       .catch((error) => {
//         console.log(error);

//         dispatch(loginFailed(error));
//       });
//   };
// };

///////////////////////////////////////////////////////////

export const updateUserData = (data) => {
  return (dispatch) => {
    dispatch(loginSuccess(data));
  };
};

export const startRegister = () => {
  return {
    type: actions.REGISTER_START,
  };
};

export const registerFailed = (error) => {
  let errorMsg = "";
  switch (error.response.status) {
    case 500:
      errorMsg = " يوجد مشكلة تقنية، سنعود لاحقاً";
      break;
    case 409:
      errorMsg = "البريد الإلكتروني مسجل مسبقاً";
      break;
    case 401:
      errorMsg = " كلمة المرور غير صحيحة";
      break;
    default:
  }
  return {
    type: actions.REGISTER_FAILED,
    errorMsg: errorMsg,
  };
};

export const registerSuccess = () => {
  return {
    type: actions.REGISTER_SUCCESS,
  };
};

export const register = (data) => {
  return (dispatch) => {
    dispatch(startRegister());
    UserService.register(data)
      .then((response) => {
        let loginData = { username: data.email, password: data.password };
        UserService.login(loginData)
          .then((response) => {
            dispatch(loginSuccess(response.data.result));
          })
          .catch((error) => {
            dispatch(loginFailed(error));
          });
      })
      .catch((error) => {
        dispatch(registerFailed(error));
      });
  };
};

export const resetError = () => {
  // removeUserStorage();
  return {
    type: actions.RESET_ERROR,
  };
};

//////////////////////////////////////////////////////////
//////////////////////////////////////////////////////////

export const logoutSuccess = () => {
  document.cookie = `user=;max-age=${1};path=/;`;
  HTTP.set_session("");
  window.setTimeout(() => {
    window.location = "/";
  }, 100);
  setActivateStorage(false);
  return (dispatch) => {
    dispatch({
      type: actions.LOGOUT,
    });
  };

  // remove user info inside redux
  // alert('logout2');

  // return {
  //   type: actions.LOGIN_SUCCESS,
  //   userInfo: userInfo
  // };
  // // - [ x ] Clear sessionStarage [authToken,fname,lname]
  // // - [ x ] Clear redux [User.auth_token,fname,lname, and any userInfno ]
  // // - [ x ] Update http Object session
  // // - [ x ] generate new  auth_token and save it inside [sessionStorage]

  // // remove storage
  // removeUserStorage();
  // // remove http session
  // HTTP.set_session(null)

  // // generate new auth_token and update HTTP.auth_token + sessionStorage
  // UserService.initiateUser()
  //   .then(response => {
  //     if (response) {
  //       HTTP.set_session(response.data['auth_token']);
  //       StorageService.setStorageElement('authToken', response.data['auth_token']);
  //       window.location.pathname = '/'
  //     }
  //   })
  //   .catch(error => {
  //     console.log('error=', error)
  //   })

  // // remove user info inside redux
  // alert('logout2');
  // return {
  //   type: actions.LOGOUT
  // };
};

export const logout = () => {
  return (dispatch) => {
    dispatch(logoutSuccess());
  };
};
