import React, { Suspense } from "react";
import "./header.scss";
import { Link } from "react-router-dom";
// import Navigation from "./navigation";
// import TopHeader from "./top_header";
import BurgerBtn from "./burger_btn";
import { withRouter } from "react-router-dom";

import { showSearch } from "stores/Search/searchActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SearchBox from "components/shared/search_block";

//import search from "../../../../src/javascript/search";

const Header = (props) => {
  let { showSearch } = props;
  console.log("props.location.pathname=", props.location.pathname);
  let location = props.location.pathname;

  const showSearchPopup = (e) => {
    showSearch();
  };
  let displaySearchIcon = true;
  if (
    // props.location.pathname === "/" ||
    props.location.pathname.substr(1, 6) === "search"
  ) {
    displaySearchIcon = false;
  }

  // let searchBar = search.setupSearchBar();
  // React.useEffect(() => search.setupSearchBar(), []);

  // let iframe = document.getElementsByTagName("iframe");

  const Navigation = React.lazy(() => import(/* webpackChunkName: "[request]" */ `./navigation`));
  const TopHeader = React.lazy(() => import(/* webpackChunkName: "[request]" */ `./top_header`));

  return (
    <header className="header">
      <div style={{ display: "none" }}>
        <SearchBox />
      </div>
      {/* <div
        dangerouslySetInnerHTML={{
          __html: iframe,
        }}
      /> */}
      {/* {searchBar} */}
      <div className="headerContainer">
        <BurgerBtn />
        <Link to="/" className="logo">
          meem app
        </Link>

        <span className="menuSection">
          <Suspense fallback={<div />}>
            <Navigation />
            <TopHeader />
          </Suspense>

          {displaySearchIcon && location != "/" && (
            <div
              className="searchHeader"
              onClick={(e) => {
                showSearchPopup(e);
              }}
            >
              Search
            </div>
          )}
        </span>
      </div>
    </header>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ showSearch }, dispatch);
};
export default connect(null, mapDispatchToProps)(withRouter(Header));
