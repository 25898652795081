import React from 'react';
import { connect } from 'react-redux';
import SearchBox from 'components/shared/search_block'

const SearchBoxContainer = (props) => {
    let { displaySearch } = props

    return (
        <>
            {displaySearch && <SearchBox />}
        </>
    )
}

const mapStateToProps = state => {
    return {
        displaySearch: state.search.displaySearch,
    };
};


export default connect(
    mapStateToProps,
    null
)(SearchBoxContainer);