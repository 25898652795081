import React from "react";
import { Helmet } from "react-helmet";
import SEO from "services/seo.service";
import Settings from "../../../services/settings.service";

import { withRouter } from "react-router-dom";
const SEOContent = ({ title, description, image, canonical = "", pageURL = "" }) => {
  let noindex = null;
  if (Settings.REACT_APP_REACT_ENV === "staging") {
    noindex = <meta name="robots" content="noindex, follow"></meta>;
  }

  return (
    <Helmet>
      <title>{title || SEO.getPageSeo("default").title}</title>
      <meta name="description" content={description || SEO.getPageSeo("default").description} />
      <meta property="og:image" content={image || SEO.getPageSeo("default").image} />
      <link rel="canonical" href={"https://www.meemapps.com" + pageURL} />

      {noindex}
    </Helmet>
  );
};
export default withRouter(SEOContent);
