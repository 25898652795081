import React from 'react';
import './spinner.scss'
const Spinner = (props) => {
    let { size } = props
    let classes = ['loader'];
    if (size === 'small') {
        classes.push('smlLoader')
    }
    return <div className={classes.join(' ')}></div>


}
export default Spinner;