import * as actions from "./actionTypes";


let initialState = {
  term: [],
  loading: false,
}


const homeTermReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.HOME_TERM_FETCH_START:
      return {
        ...state,
        loading: true
      };

    case actions.HOME_TERM_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        term: payload,
      };

    case actions.HOME_TERM_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default homeTermReducer;
