import React from "react";
import "./main_side.scss";
import SearchBox from "components/shared/search_block";

import SideColumn from "./side_column";
import { Route } from "react-router-dom";
const MainSideLayout = (props) => {
  return (
    <>
      <div className="mainSide">
        <div className="containerWrapper">
          <Route
            path="/search"
            render={(props) => <SearchBox source="search" />}
          />
          <div className="mainContent">{props.children}</div>
          <div className="sideColumn">
            <SideColumn />
          </div>
        </div>
      </div>
    </>
  );
};
export default MainSideLayout;
