import React from "react";
import ReactDOM from "react-dom";
// import './assets/scss/common.scss';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./stores/RootReducer/rootReducer";

if (process.env.REACT_APP_REACT_ENV !== "development") {
  console.log = function () {};
}

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
