import * as actions from "./actionsType";
import SearchService from "services/search.service";

const startSearchFunc = (val, dispatch) => {
  dispatch({ type: actions.SEARCH_AUTOCOMPLETE_START });
  SearchService.getGlobalAutocomplete(val)
    .then((response) => {
      dispatch({
        type: actions.SEARCH_AUTOCOMPLETE_SUCCESS,
        payload: response.data,
      });
    })
    .catch((error) => {
      dispatch({ type: actions.SEARCH_AUTOCOMPLETE_FAILED, payload: error });
    });
};
/////////////////////////////
const reset = (dispatch) => {
  dispatch({ type: actions.SEARCH_AUTOCOMPLETE_RESET });
};
//////////////////////////////
export const clearResult = () => {

  return (dispatch) => {
    dispatch({ type: actions.SEARCH_RESET });
  };
};
//////////////////////////////
export const newSearch = () => {
  return (dispatch) => {
    dispatch({ type: actions.NEW_SEARCH });
  };
};
export const writting = (val, source = null) => {
  return (dispatch) => {
    dispatch({
      type: actions.SEARCH_WRITING,
      searchText: val,
      typingTimeout: setTimeout(() => {
        if (val.length > 2) {
          if (!source) {
            startSearchFunc(val, dispatch);
          }
        }

        if (val.length === 0) {
          reset(dispatch);
        }
      }, 400),
    });
  };
};

export const language = (val) => {
  return (dispatch) => {
    dispatch({
      type: actions.LANGUAGE,
      language: val,
    });
  };
};

/////////////////////////////////////////
export const closeSearchBlock = () => {
  return (dispatch) => {
    dispatch({ type: actions.SEARCH_CLOSE });
  };
};
/////////////////////////////////////////
export const searchReset = () => {
  return (dispatch) => {
    dispatch({ type: actions.SEARCH_RESET });
  };
};
/////////////////////////////////////////
export const setFocus = () => {
  return (dispatch) => {
    dispatch({ type: actions.FOCUS });
  };
};
/////////////////////////////////////////
export const showSearch = () => {
  return (dispatch) => {
    dispatch({ type: actions.DISPLAY_SEARCH });
  };
};
/////////////////////////////////////////
export const hideSearch = () => {
  return (dispatch) => {
    dispatch({ type: actions.HIDE_SEARCH });
  };
};
/////////////////////////////////////////
export const changeSearchType = (type) => {
  return (dispatch) => {
    dispatch({ type: actions.SEARCH_CHANGE_TYPE, tab: type });
  };
};
/////////////////////////////////////////
export const searchKeyword = (keyword) => {
  return (dispatch) => {
    dispatch({ type: actions.SEARCH_KEYWORD, keyword: keyword });
  };
};
/////////////////////////////////////////
export const startSearch = () => {
  return (dispatch) => {
    dispatch({ type: actions.SEARCH_START });
  };
};
/////////////////////////////////////////
export const successSearch = (payload, count, currentPage, loadMore) => {
  return (dispatch) => {
    dispatch({
      type: actions.SEARCH_SUCCESS,
      payload: payload,
      count: count,
      currentPage: currentPage,
      loadMore: loadMore,
    });
  };
};
/////////////////////////////////////////
export const failedSearch = (error) => {
  return (dispatch) => {
    dispatch({ type: actions.SEARCH_FAILED, error: error });
  };
};
