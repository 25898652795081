import HTTP from "./http.service";

class TermsService {
  static getAllTerms(page) {
    let url = `terms/list?page=${page}`;
    let config = {
      method: "get",
      url: url,
    };
    config.headers = {
      needAutherization: true,
    };

    return HTTP(config);
  }

  static getSimilarTermsBySlug(slug) {
    let url = `terms/v2/fuzzyTerms/${slug}`;
    const config = {
      method: "get",
      url: url,
    };
    config.headers = {
      needAutherization: true,
    };

    return HTTP(config);
  }

  static getRelatedServiceBySlug(slug) {
    let url = `terms/v2/relatedService/${slug}`;
    const config = {
      method: "get",
      url: url,
    };
    config.headers = {
      needAutherization: true,
    };

    return HTTP(config);
  }

  static getRelatedAds(slug) {
    let url = `/terms/v2/relatedAds/${slug}`;
    const config = {
      method: "get",
      url: url,
    };
    config.headers = {
      needAutherization: true,
    };

    return HTTP(config);
  }

  static getRelatedTermsBySlug(slug) {
    let url = `terms/v2/relatedTerms/${slug}`;
    const config = {
      method: "get",
      url: url,
    };
    config.headers = {
      needAutherization: true,
    };

    return HTTP(config);
  }

  static getTermBySlug(slug) {
    let url = `terms/slug/${slug}`;
    let config = {
      method: "get",
      url: url,
    };
    config.headers = {
      needAutherization: true,
    };

    return HTTP(config);
  }

  static getRelatedTemplateBySlug(slug) {
    let url = `terms/v2/relatedTemplate/${slug}`;
    let config = {
      method: "get",
      url: url,
    };
    config.headers = {
      needAutherization: true,
    };

    return HTTP(config);
  }

  static suggestionAddLike(suggestionOptionId) {
    let url = `termsByUser/addLike/${suggestionOptionId}`;
    const config = {
      method: "post",
      url: url,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static suggestionRemoveLike(suggestionOptionId) {
    let url = `termsByUser/removeLike/${suggestionOptionId}`;
    const config = {
      method: "delete",
      url: url,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static addToBookmark(termId) {
    console.log("termId=", termId);
    let url = `user/bookmark`;
    const config = {
      method: "put",
      url: url,
      data: JSON.stringify({
        term: termId,
      }),
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static removeFromBookmark(termId) {
    let url = `user/bookmark/${termId}`;
    const config = {
      method: "delete",
      url: url,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static getRelatedArticlesByTermSlug(slug) {
    let url = `term/getRelatedArticle/${slug}`;
    const config = {
      method: "get",
      url: url,
    };
    return HTTP(config);
  }

  static getTermsByTemplate() {
    let url = `/getTermsByTemplate`;
    const config = {
      method: "get",
      url: url,
    };
    return HTTP(config);
  }

  static addTranslation(termTitle, id) {
    let url = `/termsByUser/add/${id}`;
    const config = {
      method: "post",
      url: url,
      data: JSON.stringify(termTitle),
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }
}

export default TermsService;
