class Translation {
    static data = {};
    static loadBackupTranslations() {
        console.log("Using backup translations");
        let keys = require("../translation/ar.json");
        this.data = keys;
        console.log(' this.data=', this.data)
    };

    static key = (k) => {
        console.log(' k=', k)
        console.log(' this.data[k]=', this.data)
        console.log(' this.data[k]=', this.data[k])
        if (this.data[k])
            return this.data[k];
        return k;
    }
}
export default Translation;