export const FOCUS = "FOCUS";
export const SEARCH_START = "SEARCH_START";
export const SEARCH_SUCCESS = "SEARCH_SUCCESS";
export const SEARCH_FAILED = "SEARCH_FAILED";

export const SEARCH_AUTOCOMPLETE_START = "SEARCH_AUTOCOMPLETE_START";
export const SEARCH_AUTOCOMPLETE_SUCCESS = "SEARCH_AUTOCOMPLETE_SUCCESS";
export const SEARCH_AUTOCOMPLETE_FAILED = "SEARCH_AUTOCOMPLETE_FAILED";
export const SEARCH_AUTOCOMPLETE_RESET = "SEARCH_AUTOCOMPLETE_RESET";

export const SEARCH_WRITING = "SEARCH_WRITINNG";
export const SEARCH_KEYWORD = "SEARCH_KEYWORD";

export const SEARCH_RESET = "SEARCH_RESET";
export const SEARCH_CLOSE = "SEARCH_CLOSE";
export const SEARCH_CHANGE_TYPE = "SEARCH_CHANGE_TYPE";

export const SEARCH_WAITE = "SEARCH_WAITE";

export const DISPLAY_SEARCH = "DISPLAY_SEARCH";
export const HIDE_SEARCH = "HIDE_SEARCH";
export const LANGUAGE = "LANGUAGE";
export const CLEAER_RESULT = "CLEAER_RESULT";
export const ISFOCUS = "ISFOCUS";

export const NEW_SEARCH = "NEW_SEARCH";
