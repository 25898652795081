import React, { Suspense } from "react";
import Layout from "layout/layout";
import Translation from "services/translation.service";
import UserService from "services/user.service";
import "App.scss";
import Routes from "routes";
import { withRouter } from "react-router-dom";

// import SearchContextProvider from 'contexts/search.context';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { logoutSuccess, updateUserData } from "stores/User/userActions";
import Util from "./util";
import ScrollToTop from "components/shared/ScrollToTop";

import ReactGA from "react-ga";

ReactGA.initialize("UA-103809144-2");

function fireTracking() {
  ReactGA.pageview(window.location.pathname);
}
function App(props) {
  props.history.listen((location, action) => {
    fireTracking();
  });

  ReactGA.pageview(window.location.pathname);

  Translation.loadBackupTranslations();

  let { updateUserData } = props;
  React.useEffect(() => {
    let userInfo = document.cookie;
    if (userInfo) {
      let user = Util.getCookie("user");
      console.log("Util.getCookie=", user);
      user = user !== "" ? JSON.parse(user) : "";
      if (user) {
        UserService.activate()
          .then((data) => {
            console.log("user activate" + data);
            if (data.data) {
              updateUserData(user);
            } else {
              console.log("user not activate" + data);

              logoutSuccess();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
  }, [updateUserData]);

  return (
    <ScrollToTop>
      <Layout>
        <Suspense fallback={<div style={{ minHeight: '100vh' }}></div>}>
          <Routes />
        </Suspense>
      </Layout>
    </ScrollToTop>
  );
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ updateUserData }, dispatch);
};

export default withRouter(connect(null, mapDispatchToProps)(App));
