import * as actions from "./actionTypes";

let initialState = {
  homeBooks: [],
  loading: false,
};

const homeBooksReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.HOME_BOOKS_FETCH_START:
      return {
        ...state,
        loading: true,
      };

    case actions.HOME_BOOKS_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        homeBooks: payload,
      };

    case actions.HOME_BOOKS_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default homeBooksReducer;
