import React from "react";

import {
  initRequestAddingTermState,
  requestAddingTermStateReducer,
} from "reducers/request_adding_term.reducer";
import Util from "../../../util";
import { useSelector } from "react-redux";

import * as constants from "variables";
import Input from "components/ui_elements/input";
import Textarea from "components/ui_elements/textarea";
import Button from "components/ui_elements/button";
import SearchService from "services/search.service";
import Spinner from "../spinner";

const RequestAddingTermForm = (props) => {
  let [requestAddingTermFormState, dispatch] = React.useReducer(
    requestAddingTermStateReducer,
    initRequestAddingTermState
  );

  let searchText = useSelector((state) => state.search.searchText);
  let userInfo = useSelector((state) => state.user.userInfo);

  let [readDefaultEmail, updateReadDefaultEmail] = React.useState(false);
  let [readDefaultMsg, updateReadDefaultMessage] = React.useState(false);

  /////////////////////////////////////////////////

  const handleChange = React.useCallback(
    (elem, newValue, state) => {
      console.log("elem===", elem);
      let newState = Util.changeHandler(elem, newValue, state);
      dispatch({
        type: constants.HANDLE_CHANGE,
        payload: newState,
        field: elem,
      });
      dispatch({ type: constants.FORM_VALIDATION });
    },
    [dispatch]
  );

  /////////////////////////////////////////////////

  let inputEmail = React.useMemo(
    () => (
      <Input
        type="email"
        value={requestAddingTermFormState.form.email.value}
        placeholder="البريد الالكتروني"
        classes={["input-field"]}
        name="email"
        id={"email"}
        error={requestAddingTermFormState.form.email.errors}
        change={handleChange}
        state={requestAddingTermFormState.form.email}
      />
    ),
    [requestAddingTermFormState.form.email, handleChange]
  );

  /////////////////////////////////////////////////
  let textArea = React.useMemo(() => {
    return (
      <Textarea
        rows={4}
        cols={50}
        name="message"
        id={"message"}
        placeholder="نص الرسالة"
        classes={["textarea"]}
        value={requestAddingTermFormState.form.message.value}
        error={requestAddingTermFormState.form.message.errors}
        change={handleChange}
        state={requestAddingTermFormState.form.message}
      />
    );
  }, [requestAddingTermFormState.form.message, handleChange]);

  /////////////////////////////////////////////////
  React.useEffect(() => {
    if (userInfo && userInfo.username && !readDefaultEmail) {
      updateReadDefaultEmail(true);
      handleChange(
        "email",
        userInfo.username,
        requestAddingTermFormState.form.email
      );
    }
  }, [
    userInfo,
    handleChange,
    requestAddingTermFormState.form.email,
    readDefaultEmail,
  ]);
  /////////////////////////////////////////////////
  React.useEffect(() => {
    if (!readDefaultMsg) {
      let msg = `لقد قمت بالبحث عن مصطلح (${searchText}) و لم أجده.\nيرجى القيام بإضافته.`;
      updateReadDefaultMessage(true);
      handleChange("message", msg, requestAddingTermFormState.form.message);
    }
  }, [
    searchText,
    handleChange,
    requestAddingTermFormState.form.message,
    readDefaultMsg,
  ]);
  /////////////////////////////////////////////////

  const postForm = () => {
    let data = {
      name: null,
      email: requestAddingTermFormState.form.email.value,
      phone: null,
      message: requestAddingTermFormState.form.message.value,
      term: null,
      type: "request_to_add",
    };
    dispatch({ type: constants.REQUEST_ADDING_TERM_START });
    SearchService.requestAddingTerm(data)
      .then((response) => {
        dispatch({ type: constants.REQUEST_ADDING_TERM_SUCCESS });
        window.setTimeout(() => {
          props.action(false);
        }, 2500);

        console.log("response result", response.data);
      })
      .catch((error) => {
        dispatch({ type: constants.REQUEST_ADDING_TERM_FAILED });
        console.log("error==", error);
      });
  };

  let content = (
    <>
      <div className="title">طلب إضافة مصطلح</div>
      {inputEmail}
      {textArea}
      <Button
        type="button"
        label="أرسل"
        theme="primary"
        action={postForm}
        disabled={!requestAddingTermFormState.formValid}
      />
    </>
  );

  if (requestAddingTermFormState.loading) {
    content = <Spinner />;
  }
  if (requestAddingTermFormState.success) {
    content = (
      <div className="successResponse">
        <div className="animation-ctn">
          <div className="icon icon--order-success svg">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="154px"
              height="154px"
            >
              <g fill="none" stroke="#22AE73" strokeWidth="2">
                <circle cx="77" cy="77" r="72" className="styleCircle"></circle>
                <circle
                  id="colored"
                  fill="#22AE73"
                  cx="77"
                  cy="77"
                  r="72"
                  className="styleCircle2"
                ></circle>
                <polyline
                  className="st0 styleCircle3"
                  stroke="#fff"
                  strokeWidth="10"
                  points="43.5,77.8 63.7,97.9 112.2,49.4 "
                />
              </g>
            </svg>
          </div>
        </div>
        <div className="successResponseMsg">تم ارسال طلبك بنجاح</div>
      </div>
    );
  }

  return <div className="addingTermFormRequest">{content}</div>;
};

export default RequestAddingTermForm;
