import * as actions from "./actionTypes";

let initialState = {
  templates: [],
  bundles: [],
  loadingTemplates: false,
  loadingBundles: false,
  currentTemplatesPage: 0,
  hasNextTemplatesPage: true,
  hasNextSearchTemplatesPage: false,
  featuredTemplate: {},
  featuredBundle: {},
  tags: [],
};

let templatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.TEMPLATES_FETCH_START:
      return {
        ...state,
        loadingTemplates: true,
        hasNextTemplatesPage: false,
      };

    case actions.TEMPLATES_FETCH_SUCCESS:
      let currentTemplatesPage = state.currentTemplatesPage + 1;
      let searchResult;
      if (action.payload.firstPage) {
        searchResult = action.payload.templates;
        currentTemplatesPage = 1;
      } else if (action.payload.templates.length > 0) {
        searchResult = [...state.templates, ...action.payload.templates];
      } else {
        searchResult = state.templates;
      }
      const featuredSearchTemplate = searchResult.find((template) => template.templatesPageFeatured) || {};
      const featuredSearchBundle = searchResult.find((template) => template.isGroupOfTemplateFeatured);
      const hasNextTemplatesPage = searchResult.length < action.payload.total;

      return {
        ...state,
        loadingTemplates: false,
        templates: searchResult,
        featuredTemplate: featuredSearchTemplate,
        hasNextTemplatesPage: hasNextTemplatesPage,
        currentTemplatesPage: currentTemplatesPage,
        featuredBundle: featuredSearchBundle,
      };

    case actions.TEMPLATES_SEARCH_FETCH_SUCCESS:
      let currentSearchTemplatesPage = state.currentTemplatesPage + 1;
      let templates;
      console.log("Templates Search" , {action})
      if (action.payload.firstPage) {
        templates = action.payload.templates;
        currentSearchTemplatesPage = 1;
      } else if (action.payload.templates.length > 0) {
        templates = [...state.templates, ...action.payload.templates];
      } else {
        templates = state.templates;
      }

    

      const featuredTemplate = templates.find((template) => template.templatesPageFeatured) || {};
      const featuredBundle = templates.find((template) => template.isGroupOfTemplateFeatured);
      // const hasNextSearchTemplatesPage = templates.length !== action.payload.total;
      const hasNextSearchTemplatesPage = action.payload.total > templates.length;

      console.log({
        ...state,
        loadingTemplates: false,
        templates: templates,
        featuredTemplate: featuredTemplate,
        hasNextSearchTemplatesPage: hasNextSearchTemplatesPage,
        currentTemplatesPage: currentSearchTemplatesPage,
        featuredBundle: featuredBundle,
      })
      return {
        ...state,
        loadingTemplates: false,
        templates: templates,
        featuredTemplate: featuredTemplate,
        hasNextSearchTemplatesPage: hasNextSearchTemplatesPage,
        currentTemplatesPage: currentSearchTemplatesPage,
        featuredBundle: featuredBundle,
      };

    case actions.FILTER_TEMPLATES_FETCH_SUCCESS:
      let currentFilterTemplatesPage = state.currentTemplatesPage + 1;
      let filterTemplates;

      filterTemplates = action.payload.templates;
      currentFilterTemplatesPage = 1;

      const featuredFilterTemplate = filterTemplates.find((template) => template.templatesPageFeatured) || {};
      const featuredFilterBundle = filterTemplates.find((template) => template.isGroupOfTemplateFeatured) || {};

      const filterhasNextTemplatesPage = filterTemplates.length <= action.payload.total;

      return {
        ...state,
        loadingTemplates: false,
        templates: filterTemplates,
        featuredTemplate: featuredFilterTemplate,
        hasNextTemplatesPage: filterhasNextTemplatesPage,
        currentTemplatesPage: currentFilterTemplatesPage,
        featuredBundle: featuredFilterBundle,
      };

    case actions.TEMPLATES_FETCH_FAILED:
      return {
        ...state,
        loadingTemplates: false,
        hasNextTemplatesPage: false,
      };

    case actions.BUNDLES_FETCH_START:
      return {
        ...state,
        loadingBundles: true,
      };

    case actions.BUNDLES_FETCH_SUCCESS:
      let bundles;
      if (action.payload.length > 0) {
        bundles = [...state.bundles, ...action.payload];
      } else {
        bundles = state.bundles;
      }

      return {
        ...state,
        loadingBundles: false,
        bundles: bundles,
        //featuredBundle: featuredBundle,
      };

    case actions.BUNDLES_FETCH_FAILED:
      return {
        ...state,
        loadingBundles: false,
      };

    case actions.TAGS_FETCH_SUCCESS:
      if (action.payload.tags.length > 0) {
        return {
          ...state,
          tags: action.payload.tags,
          //featuredBundle: featuredBundle,
        };
      }

    default:
      return state;
  }
};

export default templatesReducer;
