class StorageService {
  static removeStorageElement = (key, location = "session") => {
    if (location === "session") {
      sessionStorage.removeItem(key);
      return;
    }
    localStorage.removeItem(key);
  };

  static clearStorageElements = () => {
    sessionStorage.clear();
    localStorage.clear();
  };

  static setStorageElement = (key, value, location = "session") => {
    let content = JSON.stringify(value);
    console.log("value=", value);
    if (location === "session") {
      sessionStorage.setItem(key, content);
      return;
    }
    localStorage.setItem(key, content);
  };

  static getStorageElement = (key, location = "session") => {
    console.log("key=", key);
    if (location === "session") {
      return JSON.parse(sessionStorage.getItem(key));
    }
    return JSON.parse(localStorage.getItem(key));

    // return sessionStorage.getItem(key);
  };

  static setLoginStorage = (rememberMe, email, password) => {
    localStorage.setItem("loginEmail", email);
    localStorage.setItem("password", password);
    localStorage.setItem("rememberMe", rememberMe);
  };

  static removeLoginStorage = (rememberMe) => {
    localStorage.setItem("loginEmail", "");
    localStorage.setItem("password", "");
    localStorage.setItem("rememberMe", rememberMe);
  };
  static getLoginStorage = () => {
    let isChecked = localStorage.getItem("rememberMe") === "true";
    if (isChecked) {
      return true;
    } else {
      return false;
    }
  };

  static setActivateStorage = (Activate) => {
    localStorage.setItem("activate", Activate);
  };

  static getActivateStorage = () => {
    let isChecked = localStorage.getItem("activate") === "true";
    if (isChecked) {
      return true;
    } else {
      return false;
    }
  };
}
export default StorageService;
