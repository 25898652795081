import React from "react";
import "./input.scss";
const Input = ({ state, change, placeholder, tip, error, value, name, id, classes, type, blur, touched, ...rest }) => {
  let [typeFieled, updateTypeFieled] = React.useState(type);
  let [displayError, updateDisplayError] = React.useState(false);
  let [newClasses, updateClasses] = React.useState([...classes]);

  const isEnglish = React.useRef(false);

  console.log("=name=", name);

  let errorsMessage = null;
  const checkValidation = () => {
    // console.log('checkValidation[' + name + ']==', error.length)
    if (error.length) {
      updateClasses([...classes, "error"]);
      updateDisplayError(true);
    } else {
      updateClasses([...classes]);
      updateDisplayError(false);
    }
    if (blur) {
      blur();
    }
  };

  const changeTypeFieled = () => {
    if (typeFieled === "password") {
      updateTypeFieled("text");
    } else {
      updateTypeFieled("password");
    }
  };
  const updateInput = (e) => {
    console.log("e.target.value===", e.target.value);
    change(name, e.target.value, state);
    let val = e.target.value;
    var arabic = /[\u0600-\u06FF]/;
    var result = arabic.test(val);
    if (val === "") {
      isEnglish.current = false;
    } else {
      if (!result) {
        isEnglish.current = true;
        console.log("language");
      } else {
        isEnglish.current = false;
      }
    }
  };

  if (error.length && displayError) {
    errorsMessage = error.map((err) => (
      <div className="error-message" key={err}>
        {err}
      </div>
    ));
  }

  let displayEye = null;
  if (type === "password") {
    displayEye = <div className="eye" onClick={changeTypeFieled}></div>;
  }

  newClasses = [];
  if (isEnglish.current) {
    newClasses.push("searchInputTextEnglish");
  } else {
    newClasses.push("searchInputText");
  }

  if (state.touched || touched) {
    newClasses.push("touched");
  }

  return (
    <div className="input-field-block">
      {displayEye}
      <input
        onChange={(e) => updateInput(e)}
        onBlur={checkValidation}
        className={newClasses.join(" ")}
        type={typeFieled}
        name={name}
        id={id}
        value={value}
        {...rest}
      />
      <div className="placeholder-wrapper">
        <span className="placeholder-label">{placeholder}</span>
      </div>
      {displayError ? errorsMessage : null}
      {/* {tip ? <div className="tip" onClick={toggleTip}>{ICONS['question']}</div> : null} */}
    </div>
  );
};
export default Input;
