import React from "react";
import ReactDOM from "react-dom";

import "./search.scss";
import { Redirect, Link } from "react-router-dom";
import { writting, closeSearchBlock, setFocus, language, newSearch } from "stores/Search/searchActions";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import RequestAddingTermForm from "../../shared/search_no_result/request_adding_term";
import Button from "components/ui_elements/button";
import Modal from "../Modal";
import { BsSearch } from "react-icons/bs";

const SearchBox = (props) => {
  let [showModal, displayModal] = React.useState(false);
  // const myInp = React.useRef(null);

  let modal = null;
  if (showModal) {
    modal = (
      <Modal type="withShadow" close={() => displayModal(false)} size={600} paddingStyle={30}>
        <RequestAddingTermForm action={displayModal} />
      </Modal>
    );
  }

  console.log("props=|=|=", props);
  let {
    searchText,
    typingTimeout,
    focus,
    searchResultAutoComplete,
    closeSearchBlock,
    writting,
    setFocus,
    language,
    source,
    newSearch,
    searchResultCount,
  } = props;

  let [redirect, updateRedirect] = React.useState(null);
  const isEnglish = React.useRef(true);

  const myInp = (component) => {
    // && component.className === "searchInputText"
    if (props.focus && component) {
      setFocus();
      ReactDOM.findDOMNode(component).focus();
    }
  };
  /////////////////////////////
  const updateSearchFieled = (e) => {
    let val = e.target.value;
    var arabic = /[\u0600-\u06FF]/;
    var result = arabic.test(val);
    if (val === "") {
      isEnglish.current = false;
    } else {
      if (!result) {
        isEnglish.current = true;
        console.log("language");
      } else {
        isEnglish.current = false;
      }
    }
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    writting(val, source);
  };

  React.useEffect(() => {
    console.log("mounted");
    return () => console.log("unmounting...");
  }, [language]);
  /////////////////////////////
  const closeSearch = () => {
    closeSearchBlock();
  };
  /////////////////////////////
  const onKeyPressFunc = (event) => {
    if (event.key === "Enter") {
      newSearch();

      let search_Text = searchText.replace(/\//g, " ");

      updateRedirect(
        <Redirect
          push
          to={{
            pathname: `/search/${search_Text}`,
            state: { source: source },
          }}
        />
      );
    }
  };
  /////////////////////////////
  let classes = ["searchBox"];
  let searchContainerClasses = ["searchContainer"];
  if (focus) {
    classes.push("searchBoxFocus");
    searchContainerClasses.push("searchContainerFocus");
    document.body.classList.add("overflow");
  } else {
    document.body.classList.remove("overflow");
  }

  let content = null;
  let termsResult = null;
  let articlesResult = null;
  let servicesResult = null;
  let templateResult = null;

  console.log({searchResultAutoComplete})
  if (searchResultAutoComplete && searchResultAutoComplete?.terms?.length > 0) {
    termsResult = searchResultAutoComplete.terms.map((term) => (
      <div key={term.id}>
        <Link to={"/term/" + term.fields.slug} className="autoCompleteSuggestionItem" dangerouslySetInnerHTML={{ __html: term.suggestion }}>
        </Link>
      </div>
    ));
  }

  if (searchResultAutoComplete && searchResultAutoComplete?.articles?.length > 0) {
    articlesResult = searchResultAutoComplete.articles.map((article) => (
      <div key={article.fields.id}>
        <Link to={"/article/" + article.fields.slug} className="autoCompleteSuggestionItem" dangerouslySetInnerHTML={{ __html: article.suggestion }}>
        </Link>
      </div>
    ));
  }
  console.log({searchResultAutoComplete})
  if (searchResultAutoComplete && searchResultAutoComplete?.templates?.length > 0) {
    templateResult = searchResultAutoComplete.templates.map((template) => (
      <div key={template.id}>
        <Link to={"/template/" + template.fields.slug} className="autoCompleteSuggestionItem" dangerouslySetInnerHTML={{ __html: template.suggestion }}>
        </Link>
      </div>
    ));
  }

  console.log({templateResult})
  // if (
  //   searchResultAutoComplete&&
  //   searchResultAutoComplete.services.length > 0
  // ) {
  //   servicesResult = searchResultAutoComplete.services.map((service) => (
  //     <div key={service.fields.id}>
  //       <Link
  //         to={"/service/" + service.fields.slug}
  //         className="autoCompleteSuggestionItem"
  //       >
  //         {service.fields.suggestion}
  //       </Link>
  //     </div>
  //   ));
  // }

  if (redirect) {
    document.body.classList.remove("overflow");
  }

  content = (
    <div className="searchResultSuggestion">
      {termsResult && (
        <>
          <div className="searchResultTitle">مصطلحات</div>
          {termsResult}
        </>
      )}
      {articlesResult && (
        <>
          <div className="searchResultTitle">مقالات</div>
          {articlesResult}
        </>
      )}
      {templateResult && (
        <>
          <div className="searchResultTitle">نماذج</div>
          {templateResult}
        </>
      )}
      {servicesResult && (
        <>
          <div className="searchResultTitle">خدمات</div>
          {servicesResult}
        </>
      )}
    </div>
  );

  // if (searchResultAutoComplete && !searchResultAutoComplete.articles && !searchResultAutoComplete.terms) {
  //   content = null;
  // }

  let englishInput = (
    <>
      <input
        type="text"
        className="searchInputTextEnglish"
        placeholder="عن ماذا تبحث؟"
        onFocus={() => {
          if (source !== "search") {
            setFocus();
          }
        }}
        ref={myInp}
        value={searchText}
        onChange={(e) => updateSearchFieled(e)}
        onKeyPress={(event) => onKeyPressFunc(event)}
      />
    </>
  );

  let arabic = (
    <>
      <input
        type="text"
        className="searchInputText"
        placeholder="عن ماذا تبحث؟"
        onFocus={() => {
          if (source !== "search") {
            setFocus();
          }
        }}
        ref={myInp}
        value={searchText}
        onChange={(e) => updateSearchFieled(e)}
        onKeyPress={(event) => onKeyPressFunc(event)}
      />
    </>
  );

  let searchResulButton = null;
  searchResulButton = (
    <>
      <Button
        type="button"
        label="اعلمنا إن لم تجد ما تبحث عنه"
        theme="grey"
        action={() => {
          displayModal(true);
        }}
      />
    </>
  );

  return (
    <div className="searchWrapper">
      {modal}
      {redirect}
      <div className={classes.join(" ")}>
        <div className={searchContainerClasses.join(" ")}>
          <i className="close" onClick={closeSearch}></i>
          {/*<BiSearch />*/}
          <BsSearch
            className="searchIcon"
            onClick={() => {
              if (source !== "search") {
                setFocus();
              }
            }}
          />
          {isEnglish.current ? englishInput : arabic}
          <div className="hint">
            {!source ? "مصطلحات، مقالات، نماذج، أسئلة، خدمات" : searchResultCount + " نتيجة بحث مباشر"}
            {source && searchResulButton}
          </div>
        </div>
        <div className="searchAutoCompleteContainer">{content}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    typingTimeout: state.search.typingTimeout,
    focus: state.search.focus,
    searchText: state.search.searchText,
    searchResultAutoComplete: state.search.searchResultAutoComplete,
    searchResultCount: state.search.searchResultCount,
    language: state.search.language,
  };
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ writting, closeSearchBlock, setFocus, language, newSearch }, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchBox);
