import React, { Suspense } from "react";
import DefaultLayout from "./default";
import MainSideLayout from "./main_side";
import { withRouter } from "react-router-dom";
import Header from "../components/shared/header";
import SearchBoxContainer from "components/shared/search_block_container";


const Layout = (props) => {
  console.log(props.location.pathname.split("/"));
  let path = props.location.pathname.split("/")[1];
  let layout = null;
  console.log("props=", props);
  console.log("props.location.pathname=", props.location.pathname);
  console.log("path=", path);

  switch (path) {
    case "search":
    case "terms":
    case "topic":
    case "articles":
      layout = <MainSideLayout>{props.children}</MainSideLayout>;
      break;
    default:
      layout = <DefaultLayout>{props.children}</DefaultLayout>;
  }

  const FooterContainer = React.lazy(() => import(/* webpackChunkName: "[request]" */ `../components/shared_v2/Footer/container`));

  return (
    <>
      {/* <Helmet>
            <title>ميم | المرجع للأعمال | قاموس ونماذج وخدمات</title>
        </Helmet> */}
      <Header />
      <SearchBoxContainer />
      {layout}
      <Suspense fallback={<div />}>
        <FooterContainer />
      </Suspense>
    </>
  );
};
export default withRouter(Layout);
