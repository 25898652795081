import React from 'react';
import './related_articles.scss';
import TermsService from 'services/terms.service';
import ArticleBlockSmall from '../article_small'
const TermRelatedArticles = (props) => {

    console.log('ArticlesList props=', props)
    let { match } = props;
    let [articles, updateArticles] = React.useState(null);


    React.useEffect(() => {
        TermsService.getRelatedArticlesByTermSlug(match.params['slug'])
            .then(response => {

                updateArticles(response.data)
            })
            .catch(error => {
                console.log('error=', error)
            })
    }, [match])


    let list = null;
    if (articles && articles.length) {
        list = articles.map((article, index) => <ArticleBlockSmall key={index} data={article} />)
    }

    return (
        <div className="articlesListBlock">
            {list}
        </div>

    )
}
export default TermRelatedArticles;