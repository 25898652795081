import * as actions from "./actionTypes";


let initialState = {
  question: {},
  favoriteAnswer: '',
  loading: false,
}


const homeQAReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.HOME_QA_FETCH_START:
      return {
        ...state,
        loading: true
      };

    case actions.HOME_QA_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        question: payload.question,
        favoriteAnswer: payload.favoriteAnswer,
      };

    case actions.HOME_QA_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default homeQAReducer;
