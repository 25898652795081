import * as actions from "./actionTypes";


let initialState = {
  homeServices: [],
  loading: false,
}


const homeServicesReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case actions.HOME_SERVICES_FETCH_START:
      return {
        ...state,
        loading: true
      };

    case actions.HOME_SERVICES_FETCH_SUCCESS:
      return {
        ...state,
        loading: false,
        homeServices: payload,
      };

    case actions.HOME_SERVICES_FETCH_FAILED:
      return {
        ...state,
        loading: false,
      };

    default:
      return state;
  }
};

export default homeServicesReducer;
