import HTTP from "./http.service";
import Util from "../util";

class UserService {
  static register(data) {
    let url = `user/register`;

    const config = {
      method: "post",
      url: url,
      data: data,
    };
    return HTTP(config);
  }

  static login(data) {
    const config = {
      method: "post",
      url: "user/login",
      data: data,
    };
    return HTTP(config);
  }

  static forgetPassword(data) {
    const config = {
      method: "post",
      url: "user/forgetpassword",
      data: data,
    };
    return HTTP(config);
  }

  static resetPassword(data) {
    const config = {
      method: "post",
      url: "user/changeForgottenPassword",
      data: data,
    };
    return HTTP(config);
  }

  static changeName(data) {
    const config = {
      method: "post",
      url: "user/profile",
      data: data,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static changeUserExperience(data) {
    const config = {
      method: "post",
      url: "user/updatePracticalExperience",
      data: data,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static checkUserStatus = () => {
    let userInfo = document.cookie;

    if (userInfo) {
      let user = Util.getCookie("user");
      console.log("Util.getCookie=", user);

      if (user) {
        return true;
      }
      return null;
    }
    return null;
  };

  static bookmarkedTerms = (page = 1) => {
    const config = {
      method: "get",
      url: `user/bookmarks?page=${page}`,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  };

  static bookmarkedArticles = (page = 1) => {
    const config = {
      method: "get",
      url: `user/getBookmarkArticle?page=${page}`,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  };

  static bookmarkedTemplates = (page = 1) => {
    const config = {
      method: "get",
      url: `template/bookmarkList?page=${page}`,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  };

  static activate() {
    let url = `user/current`;
    const config = {
      method: "get",
      url: url,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static loginWithLinkedIn(data) {
    const config = {
      method: "post",
      url: "user/loginWithLinkedIn",
      data: data,
    };
    return HTTP(config);
  }

  static changeProfilePic(formData) {
    let url = `user/changeProfilePic`;
    const config = {
      method: "post",
      url: url,
      data: formData,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static addAction(action, title, templateSlug) {
    let url = `userAction`;
    const config = {
      method: "post",
      url: url,
      data: {
        action: action,
        title: title,
        templateSlug: templateSlug,
      },
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static addAction(action, title, templateSlug) {
    let url = `userAction`;
    const config = {
      method: "post",
      url: url,
      data: {
        action: action,
        title: title,
        templateSlug: templateSlug,
      },
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }

  static activateAccount(token) {
    let url = `user/activate/${token}`;
    const config = {
      method: "post",
      url: url,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }
  static sendActivationEmail() {
    let url = `user/sendActivationEmail`;
    const config = {
      method: "post",
      url: url,
      headers: {
        needAutherization: true,
      },
    };
    return HTTP(config);
  }
}

export default UserService;
