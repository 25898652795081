import React from 'react';
import './burger_btn.scss';
const BurgerBtn = (props) => {

    let classes = ['burger-btn'];
    let [showBurgerMenu, updateBurgerMenu] = React.useState(false);
    if (showBurgerMenu) {
        classes.push('opened');
        document.body.classList.add('displayNav');
    }
    else {
        document.body.classList.remove('displayNav');
    }

    return (
        <i className={classes.join(' ')} id="burger-btn" onClick={() => updateBurgerMenu(!showBurgerMenu)}></i>
    )
}
export default BurgerBtn;