import * as actions from './actionsType';

let initialState = {
  error: null,
  loading: false,
  topics: null
};
let topicsReducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.TOPICS_START:
      return {
        ...state,
        error: null,
        loading: true
      };

    case actions.TOPICS_SUCCESS:
      return {
        ...state,
        topics: action.topics,
        error: null,
        loading: false
      };

    case actions.TOPICS_FAILED:
      return {
        ...state,
        error: action.errorMsg,
        loading: false,
        topics: null
      };



    default:
      return state;
  }
};
export default topicsReducer;
