import React from "react";
import "./button.scss";
import { Link } from "react-router-dom";

const Button = (props) => {
  let classes = ["btn"];
  const { action, type, theme, path, label, location, className, ...newProps } = props;

  switch (theme) {
    case "primary":
      classes.push("btn-primary");
      break;
    case "secondary":
      classes.push("btn-secondary");
      break;
    case "grey":
      classes.push("btn-grey");
      break;
    case "download":
      classes.push("btn-download");
      break;
    case "main":
      classes.push("btn-main");
      break;
    case "blueButton":
      classes.push("blue-button");
      break;
    case "gray-button":
      classes.push("gray-button");
      break;
    case "orange-button":
      classes.push("orange-button");
      break;
    case "downloadTemplate":
      classes.push("downloadTemplate");
      break;
    case "downloadTemplateYellow":
      classes.push("downloadTemplateYellow");
      break;
    case "donationValue-button":
      classes.push("donationValue-button");
      break;

    default:
      classes.push("btn-primary");
  }

  if (className) {
    classes.push(className);
  }

  let content = (
    <input
      type={props.sumbit ? "submit" : "button"}
      className={classes.join(" ")}
      disabled={props.disabled ? "disabled" : null}
      onClick={action}
      defaultValue={label}
      location={location}
      {...newProps}
    />
  );

  if (type === "link") {
    content = (
      <Link to={path} location={location} className={classes.join(" ")}>
        {label}
      </Link>
    );
    if (newProps.blank) {
      content = (
        <a href={path} className={classes.join(" ")} target="_blank" rel="noopener noreferrer" location={location} onClick={action}>
          {label}
        </a>
      );
    }
  }

  return content;
};

export default Button;
