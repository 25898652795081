import * as actions from "./actionsType";

let initialState = {
  focus: false,
  searchText: "",
  typingTimeout: 0,
  searchResult: null,
  searchResultAutoComplete: null,
  searchResultCount: 0,
  loading: false,
  searchType: "term",
  language: false,
  displaySearch: false,
  currentPage: 0,
  loadMore: true,
  clearFieled: false
};
let searchReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FOCUS:
      return {
        ...state,
        focus: true,
      };

    case actions.SEARCH_WRITING:
      console.log("SEARCH_WRITINGSEARCH_WRITINGSEARCH_WRITINGSEARCH_WRITING");
      return {
        ...state,
        searchText: action.searchText,
        typingTimeout: action.typingTimeout,
      };

    case actions.SEARCH_KEYWORD:
      return {
        ...state,
        searchText: action.keyword,
      };
    case actions.SEARCH_CHANGE_TYPE:
      return {
        ...state,
        searchType: action.tab,
        searchResult: null,
        currentPage: 0,
      };
    case actions.SEARCH_START:
      return {
        ...state,
        // searchText: action.searchTerm,
        // typingTimeout: action.typingTimeout,
        loading: true,
        searchResult: null,
      };
    case actions.SEARCH_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResult: action.payload,
        searchResultCount: action.count,
        currentPage: action.currentPage,
        loadMore: action.loadMore,
      };

    case actions.SEARCH_RESET:
      return {
        ...state,
        focus: false,
        searchText: "",
        typingTimeout: 0,
        searchResult: null,
        searchResultAutoComplete: null,
        searchResultCount: 0,
        loading: false,
        // searchType: "term",
        displaySearch: false,
        currentPage: 0,
        loadMore: true,
        clearFieled: false
      };
    case actions.NEW_SEARCH:
      return {
        ...state,
        loading: true,
        currentPage: 0,
        loadMore: true,
        searchResult: null,
      };

    case actions.SEARCH_AUTOCOMPLETE_START:
      console.log("SEARCH_AUTOCOMPLETE_START SEARCH_AUTOCOMPLETE_START");
      return {
        ...state,
        // searchText: action.searchTerm,
        // typingTimeout: action.typingTimeout,
        loading: true,
      };
    case actions.SEARCH_AUTOCOMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        searchResultAutoComplete: action.payload,
        // searchResultCount: action.count
      };
    case actions.SEARCH_AUTOCOMPLETE_RESET:
      return {
        ...state,
        searchText: "",
        typingTimeout: 0,
        searchResultAutoComplete: null,
        loading: false,
        clearFieled: true
      };

    case actions.SEARCH_CLOSE:
      return {
        focus: false,
        searchText: "",
        typingTimeout: 0,
        searchResult: null,
        loading: false,
        displaySearch: false,
      };
    case actions.DISPLAY_SEARCH:
      return {
        displaySearch: true,
        focus: true,
      };
    case actions.HIDE_SEARCH:
      return {
        ...state,
        focus: false,
        displaySearch: false,
        // searchType: "term",
      };

    case actions.LANGUAGE:
      return {
        ...state,
        language: "",
      };

    default:
      return state;
  }
};
export default searchReducer;
